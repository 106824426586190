import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { firestore } from "../../firebase.js"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import { StaticImage } from "gatsby-plugin-image"
import { Script } from "gatsby"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Share from "../components/share.js"
import TableOfContent from "../components/table-of-content-blog.js"
import Otherpages from "../components/otherpages.js"
import Faq from "../components/faq.js"
import Footer from "../components/footer.js"
import Author from "../components/author.js"
import CommentForm from "../components/CommentForm.js"

// Картинки
import comment from "../images/icons/comment.svg"

const CommentsList = styled("ul")`
	margin-top: 50px;
  	li {
    	margin-bottom: 30px;
    	.avatar {
    		min-width: 2.5rem;
    	}
  	}
  	#reply-comment {
  		//color: rgb(49, 99, 156);
  		width: fit-content;
  	}
  	.form-comment-response {
  		padding: 20px;
    	background: rgb(246, 249, 250);
	    border-radius: 16px;
	    gap: 20px;
	    @media(max-width: 767px) {
		    padding: 10px;
		}

	    input,textarea {
		    outline: 0;
		    border-radius: 15px;
		    padding: 10px;
		    font-size: 14px;
		    width: 100%;
		}
		.send-reply {
		    background-color: #4a90e2;
		    height: 45px;
		    border-radius: 30px;
		    font-size: 14px;
		    color: #ffffff;
		    padding: 0 30px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    font-family: "Montserrat", sans-serif;
		    transition: all 0.3s;
		    width: 23%;
		    &:hover {
		      	box-shadow: rgba(30, 48, 110, 0.3) 0px 10px 26px 0px;
		      	background-color: rgb(49, 99, 156);
		    }
		    @media(max-width:992px) {
		      	width: 40%;
		    }
		}
		.cancel-response-form {
			transition: all 0.3s;

			&:hover {
				color: #4a90e2;
			}
		}
  	}
`;

const SubCommentsList = styled("ul")`
	margin-top: 20px;
	padding-left: 50px;
  	li {
    	margin-bottom: 30px;
  	}
`;

const Popup = styled("div")`
  z-index: 999;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  .popup-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    width: 30%;
    display: grid;
    text-align: center;
    gap: 10px;

    @media(max-width:992px) {
      width: 85%;
    }
  }

  h3 {
    margin: 0;
  }

  p {
    width: 80%;
    margin: auto;

    @media(max-width:992px) {
      width: 100%;
    }
  }

  img {
    margin: auto;
  }

  button {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }
`;

export default function BlogArticles({data}) {
	const [responseName, setResponseName] = useState("");
  	const [responseText, setResponseText] = useState("");
  	const [showResponseForm, setShowResponseForm] = useState({});
  	const [showPopup, setShowPopup] = useState(false);
  	const [commentAvatarColors, setCommentAvatarColors] = useState([]);
  	const [subcommentAvatarColors, setSubcommentAvatarColors] = useState([]);

  	// Оборачиваем таблицу в div 
	const addDiv = () => { 
		const el = document.querySelector('table');
		if(el) { 
			const wrapper = document.createElement('div'); 
			wrapper.classList.add('table-data-content'); 
			el.parentElement.insertBefore(wrapper, el); 
			wrapper.appendChild(el); 
		}
	};
	
	useEffect(()=>{
		addDiv(); // Оборачиваем таблицу в div 
	}, [])

	const updatedAt = data.allContentfulBlog.edges.map(({ node }) => ( node.updatedAt)) // Получаем дату обновления страницы
	const title = data.allContentfulBlog.edges.map(({ node }) => ( node.titlePage)) // Получаем заголовок страницы

	const otherArticles = data.allContentfulBlog.edges.map(({ node }, i) => ( node.otherArticles.map(pages => ( pages )) ))

	const faqs = data.allContentfulBlog.edges.map(({ node }) => ( node))

	const accordionData = [ // Создаем массив с пунктами FAQ
	  	{
	    	title: faqs[0].faqTitle1 && faqs[0].faqTitle1,
	    	content: faqs[0].faqText1 && faqs[0].faqText1.faqText1,
	  	},
	  	{
	    	title: faqs[0].faqTitle2 && faqs[0].faqTitle2,
	    	content: faqs[0].faqText2 && faqs[0].faqText2.faqText2,
	  	},
	  	{
	    	title: faqs[0].faqTitle3 && faqs[0].faqTitle3,
	    	content: faqs[0].faqText3 && faqs[0].faqText3.faqText3,
	  	},
	  	{
	    	title: faqs[0].faqTitle4 && faqs[0].faqTitle4,
	    	content: faqs[0].faqText4 && faqs[0].faqText4.faqText4,
	  	},
	  	{ 
	    	title: faqs[0].faqTitle5 && faqs[0].faqTitle5,
	    	content: faqs[0].faqText5 && faqs[0].faqText5.faqText5,
	  	},
	]

	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC'
	};

	const dateUpdate = new Date(updatedAt[0]).toLocaleString("ru", options)

	const slugPage = data.allContentfulBlog.edges.map(({ node }) => ( node.url))

  	const handleResponseSubmit = async (commentId) => {
	    const subcommentsRef = collection(firestore, "subcomments");

	    // Add a document to the 'subcomments' subcollection
	    await addDoc(subcommentsRef, {
	    	admin: '',
	      	name: responseName,
	      	text: responseText,
	      	pageIdentifier: slugPage[0],
	      	commentId: commentId,
	      	timestamp: serverTimestamp(),
	      	show: false,
	    });

	    // Clear response form data
	    setResponseName("");
	    setResponseText("");

	    setShowResponseForm((prev) => ({ ...prev, [subcommentsRef.id]: false }));

	    setShowPopup(true);
  	};

  	const openResponseForm = (commentId) => {
	    setShowResponseForm((prev) => ({ ...prev, [commentId]: true }));
	};

	const closeResponseForm = (commentId) => {
	    setShowResponseForm((prev) => ({ ...prev, [commentId]: false  }));
	};

	const closePopup = () => {
    	setShowPopup(false);
  	};

  	const handleOverlayClick = (e) => {
	    if (e.target.classList.contains('overlay')) {
	      	closePopup();
	    }
  	};

	const subcomments = data.allSubcomments.edges.map(({ node }) => ( node))

	// Function to generate a random color in hex format
	const getRandomColor = () => {
	    const letters = '0123456789ABCDEF';
	    let color = '#';
	    for (let i = 0; i < 6; i++) {
	      color += letters[Math.floor(Math.random() * 16)];
	    }
	    return color;
	};

  	// Set the initial random colors on component mount
  	useEffect(() => {
	    const initialColors = Array.from(
	      { length: data.allComments.edges.length },
	      () => getRandomColor()
	    );
	    setCommentAvatarColors(initialColors);
	}, [data]);

	// Set the initial random colors for subcomments on component mount or when data changes
	useEffect(() => {
	    const initialColors = Array.from(
	      { length: data.allSubcomments.edges.length },
	      () => getRandomColor()
	    );
	    setSubcommentAvatarColors(initialColors);
	}, [data]);

	const handleTextAreaChange = (e) => {
	    const textarea = e.target;
	    textarea.style.height = 'auto'; // Reset the height to auto to recalculate the scrollHeight
	    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
	    setResponseText(textarea.value)
	};

	return (
		<>	
			{data.allContentfulBlog.edges.map(({ node }) => (
				<div key={Math.random()}>
					<Seo title={node.title} description={node.description} />
					<Helmet>
						<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Блог","item": "https://sleepadvisor.ru/blog/"},{"@type": "ListItem","position": 3,"name": "${node.titlePage}"}]}`}</script>
						<script type="application/ld+json">{`{"@context":"https://schema.org","@graph":[{"@type":"Article","mainEntityOfPage":{"@type":"WebPage","@id":"https://sleepadvisor.ru/${node.url}/"},"headline":"${node.titlePage}","description":"${node.description}","image":[{"@type":"ImageObject","url":"${node.image.file.url}","copyrightHolder":{"@type":"Organization","name":"SleepAdvisor"},"width":${node.image.file.details.image.width},"height":${node.image.file.details.image.height}}],"author":{"@type":"Person","name":"Оксана Федосова","knowsAbout":"Эксперт по матрасам и другим товарам для сна","url":"https://sleepadvisor.ru/author.jpg"},"publisher":{"@type":"Organization","name":"SleepAdvisor","logo":{"@type":"ImageObject","url":"https://sleepadvisor.ru/logo.svg"}},"datePublished":"${node.createdAt}","dateModified":"${node.updatedAt}","potentialAction":{"@type":"ReadAction","target":{"@type":"EntryPoint","urlTemplate":"https://sleepadvisor.ru/${node.url}/"}}}]}`}</script>
					</Helmet>
				</div>
			))}
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item"><a href="/blog/">Блог</a></li>
					                <li className="breadcrumb-item active" aria-current="page">{title}</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>{title}</h1>
					<p className="date-modified font-[merriweather]">Обновлено {dateUpdate.replace(' г.', '')}</p>
				</div>
			</div>
			<section className="content texting py-8 max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
				<div className="container">
					{data.allContentfulBlog.edges.map(({ node }) => (
						<div key={node.id} className="text-lg font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.minitext.childMarkdownRemark.html }}/>
					))}
					{/*<div id="yandex_rtb_R-A-690338-10"></div>*/}
					{data.allContentfulBlog.edges.map(({ node }) => (
						<TableOfContent postsToRender={node} />
					))}
				    {data.allContentfulBlog.edges.map(({ node }) => (
				    	<div key={node.id} className="text_content text-lg font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.text.childMarkdownRemark.html }}/>
				    ))}
				    <div id="yandex_rtb_C-A-690338-7"></div>
				    <Share />
				    <Author />
				</div>
			</section>
			{faqs[0].faqTitle1 && (<section className="faq py-8 max-w-[750px] mx-auto px-4">
				<div className="container">
					<h2 id="faq">Вопрос–Ответ</h2>
					<div className="accordeon-custom" itemScope itemType="https://schema.org/FAQPage">
						<div className="accordeon-custom__items">
							{accordionData.map(({ title, content }) => (
								<Faq key={Math.random()} title={title} content={content} />
							))}
						</div>
					</div>
				</div>
			</section> )}
			<Otherpages otherArticles={otherArticles[0]} titlePage="статьи" />
			<section className="comments py-8 max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<h2 id="comment">Комментарии</h2>
				<CommentForm pageIdentifier={slugPage[0]} />
				<div id="comments">
					<CommentsList>
						{data.allComments.edges.map(({ node}, index ) => (
			                <li key={node.id}>
				                <div className="flex gap-x-4">
				                	{node.admin === 'true' ?
				                		<div className="avatar w-10 h-10 flex items-center justify-center relative">
					                		<StaticImage src="../images/author.jpg" className="rounded-full" alt="Оксана Федосова" placeholder="none" quality={100} />
					                		<StaticImage src="../images/admin-icon.png" alt="Администратор" placeholder="none" quality={100} width={16} height={16} className="absolute -right-0.5 -bottom-0.5" />
				                		</div>
				                		:
					                	<div className="avatar rounded-full w-10 h-10 flex items-center justify-center text-white text-xl capitalize" style={{ backgroundColor: commentAvatarColors[index] }}>{node.name.charAt(0)}</div>
					                }
					                <div className="grid gap-y-2 w-full">
						                <div className="flex gap-x-4 items-center">
					                  		<p className="font-semibold capitalize">{node.name}</p>
					                  		{node.admin === 'true' && <p className="bg-[#4A90E2] text-xs text-white rounded-lg px-1.5">Редакция</p>}
					                  		<p className="text-slate-400 text-xs">{new Intl.DateTimeFormat('ru-RU', options).format(new Date(node.timestamp))}</p>
					                  	</div>
				                  		<p className="font-[merriweather] text-sm leading-6">{node.text}</p>
				                  		<button id="reply-comment" className="text-sm text-slate-400" onClick={() => openResponseForm(node.id)}>Ответить</button>
				                  		{showResponseForm[node.id] && (
								            <div className="form-comment-response grid mt-4">
						                  		<input
								                  type="text"
								                  name={`response-name-${node.id}`}
								                  value={responseName}
								                  onChange={(e) => setResponseName(e.target.value)}
								                  placeholder="Имя"
								                />
								                <textarea
								                  name={`response-text-${node.id}`}
								                  value={responseText}
								                  onChange={(e) => handleTextAreaChange(e)}
								                  placeholder="Написать ответ..."
								                  className="h-auto resize-none overflow-hidden"
										          rows="1"
										          type="text"
								                />
								                <div className="flex justify-end gap-x-4">
									                <button className="cancel-response-form text-sm" onClick={() => closeResponseForm(node.id)}>Отмена</button>
									                <button className="send-reply" onClick={() => handleResponseSubmit(node.id)}>Отправить</button>
								                </div>
								            </div>
							            )}
				                  	</div>
					            </div>
					            <SubCommentsList>
					            	{subcomments.map((item, i) => (
					            		node.id === item.commentId && (
							            	<li>
							            		<div className="flex gap-x-4">
								            		{item.admin === 'true' ?
								                		<div className="avatar w-10 h-10 flex items-center justify-center relative">
									                		<StaticImage src="../images/author.jpg" className="rounded-full" alt="Оксана Федосова" placeholder="none" quality={100} />
									                		<StaticImage src="../images/admin-icon.png" alt="Администратор" placeholder="none" quality={100} width={16} height={16} className="absolute -right-0.5 -bottom-0.5" />
								                		</div>
								                		:
									                	<div className="avatar rounded-full w-10 h-10 flex items-center justify-center text-white text-xl capitalize" style={{backgroundColor: subcommentAvatarColors[i]}}>{item.name.charAt(0)}</div>
									                }
									                <div className="grid gap-y-2  w-full">
										                <div className="flex gap-x-4 items-center">
									                  		<p className="font-semibold capitalize">{item.name}</p>
									                  		{item.admin === 'true' && <p className="bg-[#4A90E2] text-xs text-white rounded-lg px-1.5">Редакция</p>}
									                  		<p className="text-slate-400 text-xs">{new Intl.DateTimeFormat('ru-RU', options).format(new Date(item.timestamp))}</p>
									                  	</div>
								                  		<p className="font-[merriweather] text-sm leading-6">{item.text}</p>
								                  		<button id="reply-comment" className="text-sm text-slate-400" onClick={() => openResponseForm(item.id)}>Ответить</button>
								                  		{showResponseForm[item.id] && (
												            <div className="form-comment-response grid mt-4">
										                  		<input
												                  type="text"
												                  name={`response-name-${item.id}`}
												                  value={responseName}
												                  onChange={(e) => setResponseName(e.target.value)}
												                  placeholder="Имя"
												                />
												                <textarea
												                  name={`response-text-${item.id}`}
												                  value={responseText}
												                  onChange={(e) => handleTextAreaChange(e)}
												                  placeholder="Написать ответ..."
												                  className="h-auto resize-none overflow-hidden"
														          rows="1"
														          type="text"
												                />
												                <div className="flex justify-end gap-x-4">
													                <button className="cancel-response-form text-sm" onClick={() => closeResponseForm(item.id)}>Отмена</button>
													                <button className="send-reply" onClick={() => handleResponseSubmit(item.id)}>Отправить</button>
												                </div>
												            </div>
											            )}
								                  	</div>
									            </div>
									            <SubCommentsList>
									            	{subcomments.map((com, subi) => (
									            		item.id === com.commentId && (
											            	<li>
											            		<div className="flex gap-x-4">
											            			{com.admin === 'true' ?
												                		<div className="avatar w-10 h-10 flex items-center justify-center relative">
													                		<StaticImage src="../images/author.jpg" className="rounded-full" alt="Оксана Федосова" placeholder="none" quality={100} />
													                		<StaticImage src="../images/admin-icon.png" alt="Администратор" placeholder="none" quality={100} width={16} height={16} className="absolute -right-0.5 -bottom-0.5" />
												                		</div>
												                		:
													                	<div className="avatar rounded-full w-10 h-10 flex items-center justify-center text-white text-xl capitalize" style={{ backgroundColor: subcommentAvatarColors[subi] }}>{com.name.charAt(0)}</div>
													                }												                	
													                <div className="grid gap-y-2  w-full">
														                <div className="flex gap-x-4 items-center">
													                  		<p className="font-semibold capitalize">{com.name}</p>
													                  		{com.admin === 'true' && <p className="bg-[#4A90E2] text-xs text-white rounded-lg px-1.5">Редакция</p>}
													                  		<p className="text-slate-400 text-xs">{new Intl.DateTimeFormat('ru-RU', options).format(new Date(com.timestamp))}</p>
													                  	</div>
												                  		<p className="font-[merriweather] text-sm leading-6">{com.text}</p>
												                  	</div>
													            </div>
											            	</li>
										            	)
									            	))}
									            </SubCommentsList>
							            	</li>
						            	)
					            	))}
					            </SubCommentsList>
			                </li>
						))}
					</CommentsList>
				</div>
			</section>
			{showPopup && (
		        <Popup onClick={handleOverlayClick} className="overlay">
		          	<div className="popup-container">
		            	<img src={comment} alt="Комментарии" loading="lazy" width="70" height="70" />
		            	<h3>Спасибо</h3>
		            	<p>Ваш комментарий уже в пути и будет добавлен сразу после модерации</p>
		            	<button onClick={closePopup}>x</button>
		          	</div>
		        </Popup>
		    )}

		    {/*Баннер мобильный. Низ*/}
	        <Script strategy="idle"
	          dangerouslySetInnerHTML={{
	            __html: `
	              (function (w) {
	                window.yaContextCb.push(()=>{
	                  Ya.Context.AdvManager.render({
	                    "blockId": "R-A-690338-11",
	                    "type": "floorAd"
	                  })
	                })
	              })(window);
	            `,
	          }}
	        />
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query($slug: String!) {
    	allContentfulBlog(filter: { url: { eq: $slug } }) {
      		edges {
	        	node {
	          		id
	          		url
	          		titlePage
	          		title
	          		description
	          		updatedAt
	          		createdAt
	          		minitext {
	          			childMarkdownRemark {
		            		html
		        		}
	          		}
	          		text {
	          			text
	          			childMarkdownRemark {
		            		html
		            		headings(depth: h2) {
				                value
				                id
				            }
		        		}
	          		}
	          		otherArticles {
	          			__typename
            			... on ContentfulBlog {
            				id
			          		url
			          		titlePage
            			}
            			... on ContentfulPagesMattress {
            				id
			          		url
			          		titlePage
            			}
	          		}
	          		faqTitle1
			        faqTitle2
			        faqTitle3
			        faqTitle4
			        faqText1 {
			          	faqText1
			        }
			        faqText2 {
			          	faqText2
			        }
			        faqText3 {
			          	faqText3
			        }
			        faqText4 {
			          	faqText4
			        }
			        image {
			          	file {
			            	url
			            	details {
			              		image {
				                	height
				                	width
			              		}
			            	}
			          	}
			        }
	        	}
	      	}
    	}
    	allComments(filter: { pageIdentifier: { eq: $slug }, show: {ne: false} }) {
		    edges {
		      	node {
		      		id
			        name
			        pageIdentifier
			        text
			        timestamp
			        admin
		      	}
		    }
		}
		allSubcomments(filter: { pageIdentifier: { eq: $slug }, show: {ne: false} }) {
		    edges {
		      	node {
		      		id
			        name
			        pageIdentifier
			        text
			        timestamp
			        commentId
			        admin
		      	}
		    }
		}
  	}
`