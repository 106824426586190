import React, { useState } from 'react'
import { firestore } from "../../firebase.js"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import styled from "styled-components"

// Картинки
import comment from "../images/icons/comment.svg"

const CommentsForm = styled("div")`
  padding: 30px;
  background: rgb(246, 249, 250);
  border-radius: 16px;
  display: grid;
  gap: 30px;
  @media(max-width: 767px) {
    padding: 10px;
  }
  input,textarea {
    outline: 0;
    border-radius: 15px;
    padding: 10px;
    font-size: 14px;
    width: 100%;
  }
  button {
    background-color: #4a90e2;
    height: 45px;
    border-radius: 30px;
    font-size: 14px;
    color: #ffffff;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s;
    width: 23%;
    margin-left: auto;
    margin-top: 30px;
    &:hover {
      box-shadow: rgba(30, 48, 110, 0.3) 0px 10px 26px 0px;
      background-color: rgb(49, 99, 156);
    }
    @media(max-width:992px) {
      width: 40%;
    }
  }
`;

const Popup = styled("div")`
  z-index: 999;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  .popup-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    width: 30%;
    display: grid;
    text-align: center;
    gap: 10px;

    @media(max-width:992px) {
      width: 85%;
    }
  }

  h3 {
    margin: 0;
  }

  p {
    width: 80%;
    margin: auto;

    @media(max-width:992px) {
      width: 100%;
    }
  }

  img {
    margin: auto;
  }

  button {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }
`;

const CommentForm = ({ pageIdentifier }) => {
  const [commentData, setCommentData] = useState({
    name: '',
    text: '',
    admin: '',
  });
  const [showPopup, setShowPopup] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const handleCommentSubmit = async () => {
    if (commentData.text.trim() !== '') {
      const commentsRef = collection(firestore, 'comments');
      
      // const docRef = await addDoc(commentsRef, {
      await addDoc(commentsRef, {
        ...commentData,
        pageIdentifier: pageIdentifier,
        timestamp: serverTimestamp(),
        show: false,
      });

      setCommentData({
        name: '',
        text: '',
        admin: '',
      });

      setShowPopup(true);
    }
    // const querySnapshot = await getDocs(collection(firestore, "comments"));
    // querySnapshot.forEach((doc) => {
    //   console.log(doc.data());
    // });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCommentData({
      ...commentData,
      [name]: value,
    });
  };

  const handleTextAreaChange = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto'; // Reset the height to auto to recalculate the scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
    setCommentData({
      ...commentData,
      text: textarea.value,
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('overlay')) {
      closePopup();
    }
  };

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  return (
    <>
      <CommentsForm>
        <textarea
          name="text"
          value={commentData.text}
          onChange={(e) => handleTextAreaChange(e)}
          onFocus={handleTextareaFocus}
          placeholder="Написать комментарий..."
          className="h-auto resize-none overflow-hidden"
          rows="1"
          type="text"
        />
        {isTextareaFocused && (
          <div>
            <input type="text" name="name" value={commentData.name} onChange={handleInputChange} placeholder="Имя" />
            <button onClick={handleCommentSubmit}>Отправить</button>
          </div>
        )}
      </CommentsForm>

      {showPopup && (
        <Popup onClick={handleOverlayClick} className="overlay">
          <div className="popup-container">
            <img src={comment} alt="Комментарии" loading="lazy" width="70" height="70" />
            <h3>Спасибо</h3>
            <p>Ваш комментарий уже в пути и будет добавлен сразу после модерации</p>
            <button onClick={closePopup}>x</button>
          </div>
        </Popup>
      )}
    </>
  );
};

export default CommentForm;
